import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Showcase/layout"
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const VisualDesign = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="On-Page Content Strategy, Copywriting & Production Planning" />
    <section className="intro-section">
      <span className="tag-line">Page-by-Page Content Strategy | Copywriting | Interactive Web Content | User Acceptance Testing</span>
      <h1>On-Page Content Strategy, Copywriting & Production Planning</h1>
      <p>Our team selects <strong>enrollment-critical pages</strong> to use as examples for how to develop content on the new site. We start with <strong>visual content briefs</strong> that show the modules to use, content hierarchy, and various SEO mark-up; we’ll also include page-specific suggestions for the type of images, videos, testimonials, and stats. From there, we’ll begin <strong>writing copy</strong> — and suggest pages your team should prioritize updating.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/content-strategy/content-strategy.jpg"
        width={4292}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Content Strategy"
        />
      </Parallax>
      <figcaption>Content Strategy</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Visual Content Strategies - Voice and Tone Guide - Page Goals and Keywords - H-Tag Mark-up - Interactive Web Content - Copywriting - Post-Launch Content Planning - Partner Page Priortization</p>
      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/content-strategy/landing-page-content-strategy.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Landing Page Content Strategy"
        />
        
        </figure>
        <figcaption>Landing Page Content Strategy</figcaption>
      </div>


      <div className="cols">
        <div className="portfolio-card col-50">
          <figure>
          <StaticImage
          src="../assets/images/content-strategy/mobile-view.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Mobile View"
          />
          
          </figure>
          <figcaption>Mobile View</figcaption>
        </div>
        <div className="portfolio-card parallax col-50">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/content-strategy/style-guide.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Style Guide"
        />
        
        </figure>
        </Parallax>
        <figcaption>Style Guide</figcaption>
      </div>
      </div>

      <div className="portfolio-card parallax">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/content-strategy/page-goals-metadata-seo.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Page Goals, SEO Keywords and Metadata"
        />
        </figure>
        </Parallax>
        <figcaption>Page Goals, SEO Keywords and Metadata</figcaption>
      </div>


      <div className="cols">
        <div className="portfolio-card col-50">
        <Parallax translateY={["50", "-30"]}>

          <figure>
          <StaticImage
          src="../assets/images/content-strategy/copywriting.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Copywriting"
          />
          
          </figure>
        </Parallax>
          <figcaption>Copywriting</figcaption>
        </div>
        <div id="scrolltohere" className="portfolio-card parallax col-50">
      
      <figure>

        <StaticImage
        src="../assets/images/content-strategy/content-development-guide.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Content Development Guide"
        />
        
        </figure>
        <figcaption>Content Development Guide</figcaption>
      </div>
      </div>

      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/content-strategy/interactive-web-content.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Interactive Web Content"
        />
        
        </figure>
        <figcaption>Interactive Web Content</figcaption>
      </div>

    
    </section>


    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">
          <li className="content-card">
            <Link to="/seo-analytics-and-reporting">
              <StaticImage
                src="../assets/images/seo-analytics-and-reporting.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>SEO Analytics & Reporting</h3>
                <p>Information Architecture | Content Strategy | SEO Strategy | Creative Brief</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="/discovery-and-planning">
              <StaticImage
                src="../assets/images/discovery-and-planning.png"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Discovery & Planning</h3>
                <p>Project Plan | Foundational Strategy | Technology & Platform Plan</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>
    
  </Layout>
  </ParallaxProvider>
)

export default VisualDesign
